const en = {
  search_field: 'Search field',
  field_not_found: 'Field not found',
  clear: 'Clear',
  align: 'Align',
  add_all_required_fields_to_continue: 'Add all required fields to continue',
  left: 'Left',
  right: 'Right',
  center: 'Center',
  description: 'Description',
  display_title: 'Display title',
  with_logo: 'With logo',
  unchecked: 'Unchecked',
  equal: 'Equal',
  not_equal: 'Not equal',
  contains: 'Contains',
  does_not_contain: 'Does not contain',
  not_empty: 'Not empty',
  empty: 'Empty',
  select_field_: 'Select field...',
  select_value_: 'Select value...',
  remove_condition: 'Remove condition',
  are_you_sure: 'Are you sure?',
  sign_yourself: 'Sign Yourself',
  set_signing_date: 'Set signing date',
  send: 'Send',
  remove: 'Remove',
  edit: 'Edit',
  settings: 'Settings',
  up: 'Up',
  down: 'Down',
  checked: 'Checked',
  save: 'Save',
  cancel: 'Cancel',
  any: 'Any',
  drawn: 'Drawn',
  formula: 'Formula',
  typed: 'Typed',
  draw_field_on_the_document: 'Draw {field} field on the document',
  click_to_upload: 'Click to upload',
  or_drag_and_drop_files: 'or drag and drop files',
  uploading: 'Uploading',
  processing_: 'Processing...',
  add_pdf_documents_or_images: 'Add PDF documents or images',
  add_documents_or_images: 'Add documents or images',
  required: 'Required',
  default_value: 'Default value',
  format: 'Format',
  read_only: 'Read-only',
  page: 'Page',
  draw_new_area: 'Draw New Area',
  copy_to_all_pages: 'Copy to All Pages',
  add_option: 'Add option',
  option: 'Option',
  condition: 'Condition',
  first_party: 'First Party',
  second_party: 'Second Party',
  third_party: 'Third Party',
  fourth_party: 'Fourth Party',
  fifth_party: 'Fifth Party',
  sixth_party: 'Sixth Party',
  seventh_party: 'Seventh Party',
  eighth_party: 'Eighth Party',
  ninth_party: 'Ninth Party',
  tenth_party: 'Tenth Party',
  eleventh_party: 'Eleventh Party',
  twelfth_party: 'Twelfth Party',
  thirteenth_party: 'Thirteenth Party',
  fourteenth_party: 'Fourteenth Party',
  fifteenth_party: 'Fifteenth Party',
  sixteenth_party: 'Sixteenth Party',
  seventeenth_party: 'Seventeenth Party',
  eighteenth_party: 'Eighteenth Party',
  nineteenth_party: 'Nineteenth Party',
  twentieth_party: 'Twentieth Party',
  draw: 'Draw',
  add: 'Add',
  or_add_field_without_drawing: 'Or add field without drawing',
  text: 'Text',
  number: 'Number',
  signature: 'Signature',
  initials: 'Initials',
  date: 'Date',
  image: 'Image',
  file: 'File',
  select: 'Select',
  checkbox: 'Checkbox',
  multiple: 'Multiple',
  radio: 'Radio',
  cells: 'Cells',
  stamp: 'Stamp',
  payment: 'Payment',
  phone: 'Phone',
  field: 'Field',
  group: 'Group',
  draw_a_text_field_on_the_page_with_a_mouse: 'Draw a text field on the page with a mouse',
  draw_field: 'Draw {field} Field',
  replace: 'Replace',
  uploading_: 'Uploading...',
  add_document: 'Add Document'
}

const el = {
  search_field: 'Πεδίο Αναζήτησης',
  field_not_found: 'Το πεδίο δεν βρέθηκε',
  clear: 'Εκαθάρηση',
  align: 'Ευθυγράμμιση',
  add_all_required_fields_to_continue: 'Προσθέστε όλα τα απαιτούμενα πεδία για να συνεχίσετε',
  left: 'Αριστερά',
  right: 'Δεξιά',
  center: 'Κέντρο',
  description: 'Περιγραφή',
  display_title: 'Εμφάνιση τίτλου',
  with_logo: 'Με λογότυπο',
  unchecked: 'Χωρίς έλεγχο',
  equal: 'Ισοδύναμο',
  not_equal: 'Δεν είναι ίσα',
  contains: 'Περιέχει',
  does_not_contain: 'Δεν περιέχει',
  not_empty: 'Δεν είναι άδειο',
  empty: 'Άδειο',
  select_field_: 'Επιλέξτε πεδίο...',
  select_value_: 'Επιλέξτε τιμή...',
  remove_condition: 'Κατάργηση συνθήκης',
  are_you_sure: 'Είσαι σίγουρος?',
  sign_yourself: 'Υπογραψτε',
  set_signing_date: 'Ορισμός ημερομηνίας υπογραφής',
  send: 'Αποστολη',
  remove: 'Αφαίρεση',
  edit: 'Επεξεργασία',
  settings: 'Ρυθμίσεις',
  up: 'Πάνω',
  down: 'Κάτω',
  checked: 'Ελεγμένο',
  save: 'Αποθηκευση',
  cancel: 'Ακύρωση',
  any: 'Οποιοδήποτε',
  drawn: 'Σχεδιασμένο',
  formula: 'Φόρμουλα',
  typed: 'Πληκτρολογημένο',
  draw_field_on_the_document: 'Σχεδιάστε το πεδίο {field} στο έγγραφο',
  click_to_upload: 'Κάντε κλικ για να ανεβάσετε',
  or_drag_and_drop_files: 'ή σύρετε και αφήστε αρχεία',
  uploading: 'Ανέβασμα',
  processing_: 'Επεξεργασία...',
  add_pdf_documents_or_images: 'Προσθήκη εγγράφων ή εικόνων PDF',
  add_documents_or_images: 'Προσθήκη εγγράφων ή εικόνων',
  required: 'Απαιτούμενο',
  default_value: 'Προεπιλεγμένη τιμή',
  format: 'Μορφή',
  read_only: 'Μόνο για ανάγνωση',
  page: 'Σελίδα',
  draw_new_area: 'Σχεδιάστε νέα περιοχή',
  copy_to_all_pages: 'Αντιγραφή σε όλες τις σελίδες',
  add_option: 'Προσθήκη επιλογής',
  option: 'Επιλογή',
  condition: 'Συνθήκη',
  first_party: 'Πρώτο Άτομο',
  second_party: 'Δεύτερο Άτομο',
  third_party: 'Τρίτο Άτομο',
  fourth_party: 'Τέταρτο Άτομο',
  fifth_party: 'Πέμπτο Άτομο',
  sixth_party: 'Έκτο Άτομο',
  seventh_party: 'Έβδομο Άτομο',
  eighth_party: 'Όγδοο Άτομο',
  ninth_party: 'Ένατο Άτομο',
  tenth_party: 'Δέκατο Άτομο',
  eleventh_party: 'Ενδέκατο Άτομο',
  twelfth_party: 'Δωδέκατο Άτομο',
  thirteenth_party: 'Δέκατο τρίτο Άτομο',
  fourteenth_party: 'Δέκατο τέταρτο Άτομο',
  fifteenth_party: 'Δέκατο πέμπτο Άτομο',
  sixteenth_party: 'Δέκατο έκτο Άτομο',
  seventeenth_party: 'Δέκατο έβδομο Άτομο',
  eighteenth_party: 'Δέκατο όγδοο Άτομο',
  nineteenth_party: 'Δέκατο ένατο Άτομο',
  twentieth_party: 'Εικοστό Άτομο',
  draw: 'Σχέδιο',
  add: 'Προσθήκη',
  or_add_field_without_drawing: 'Ή προσθέστε πεδίο χωρίς σχέδιο',
  text: 'Κείμενο',
  number: 'Αριθμός',
  signature: 'Υπογραφή',
  initials: 'Αρχικά',
  date: 'Ημερομηνία',
  image: 'Εικόνα',
  file: 'Αρχείο',
  select: 'Επιλογή',
  checkbox: 'Κουτάκι ελέγχου',
  multiple: 'Πολλαπλή',
  radio: 'Ράδιο',
  cells: 'Κύτταρα',
  stamp: 'Σφραγίδα',
  payment: 'Πληρωμή',
  phone: 'Τηλέφωνο',
  field: 'Πεδίο',
  group: 'Ομάδα',
  draw_a_text_field_on_the_page_with_a_mouse: 'Σχεδιάστε ένα πεδίο κειμένου στη σελίδα με το ποντίκι',
  draw_field: 'Σχεδιάστε {πεδίο} πεδίο',
  replace: 'Αντικατάσταση',
  uploading_: 'Ανέβασμα...',
  add_document: 'Προσθηκη'
}

export { en, el }
